import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  INVENTORY_WORKFLOW,
  MATERIAL_STOCK_STATUS,
} from '@iarcpsu/emanufac-constant';
import { Button, TableCell, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { api } from '@/configs';
import { previousLotReciptDateSafe } from '@/utils/functions';

import EachLotDurationOnTimePairOnTableRow from '../../Box/Material/EachLotDurationOnTimePairOnTableRow';

const MaterialDurationTableRow = ({
  selectedLot,
  showStatus,
  imsSetting,
  index,
}) => {
  const information = useSelector((state) => state.information);
  const [previousLotList, setPreviousLotList] = useState([]);
  const [paritedLotList, setPairedLotList] = useState([]);

  const findWorkType = (representStartLot, representEndLot) => {
    // console.log('Represent Start Lot', representStartLot);
    // console.log('Represent End Lot', representEndLot);
    // console.log('---------');
    if (representStartLot?.place?._id === representEndLot?.place?._id) {
      // รับวัตถุดิบเข้า
      if (_.isEmpty(representStartLot?.previous_lot)) {
        // ยังได้วัตถุดิบชิ้นเดียวกัน
        if (
          representStartLot?.material?._id === representEndLot?.material?._id
        ) {
          return INVENTORY_WORKFLOW.INITIAL_WAITING.status_code;
        }

        return INVENTORY_WORKFLOW.INITIAL_IN_PROGRESS.status_code;
      }
      // ยังได้วัตถุดิบชิ้นเดียวกัน
      if (representStartLot?.material?._id === representEndLot?.material?._id) {
        return INVENTORY_WORKFLOW.WAITING.status_code;
      }

      return INVENTORY_WORKFLOW.IN_PROGRESS.status_code;

      // วัตถุดิบ มาจากขั้นตอนอื่น
    }
    return INVENTORY_WORKFLOW.IN_PLACE_WAITING_TO_MOVE.status_code;
  };

  const getPreviousLots = async () => {
    try {
      const { data } = await api.get(
        `${process.env.REACT_APP_API_URL}/material-stock-lot/with-previous-lot/${selectedLot._id}`,
      );
      const tempPreviousLot = data?.rows;
      const uniqueLots = _.uniqBy(tempPreviousLot, '_id');
      const tempOrderedLot = _.orderBy(uniqueLots, 'createdAt');
      setPreviousLotList(tempOrderedLot);
      const tempPairedLot = [];

      const orderedLot = _.orderBy(
        previousLotReciptDateSafe(tempOrderedLot),
        'receipt_date',
      );

      const groupByDatedLots = _.groupBy(orderedLot, 'receipt_date');
      const timeKey = _.keys(groupByDatedLots);
      const pairTimeKey = _.zip(timeKey, timeKey.slice(1));
      const filterPairTime = _.filter(
        pairTimeKey,
        (each) => each?.[1] !== undefined,
      );

      _.map(filterPairTime, (timePair, pairIndex) => {
        const timeUsage = dayjs(timePair?.[1]).diff(
          dayjs(timePair?.[0]),
          'minute',
        );
        const startLots = groupByDatedLots[timePair?.[0]];
        const endLots = groupByDatedLots[timePair?.[1]];

        const representStartLot = _.first(startLots);
        const representEndLot = _.last(endLots);
        const payload = {
          workType: findWorkType(representStartLot, representEndLot),
          startLot: representStartLot,
          endLot: representEndLot,
          timePair,
          timeUsage,
          pairIndex,
          startPlace: representStartLot?.place,
          endPlace: representEndLot?.place || representEndLot?.warehouse,
        };

        tempPairedLot.push(payload);
      });

      setPairedLotList(tempPairedLot);
    } catch (error) {
      console.error('Error On Fetching Previous Lot', error);
    }
  };

  useEffect(() => {
    getPreviousLots();

    return () => {};
  }, [selectedLot]);

  return (
    <TableRow>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        {selectedLot?.running_number}
        {showStatus && (
          <div>
            {selectedLot?.status ===
            MATERIAL_STOCK_STATUS.IN_STOCK.status_code ? (
              <div className="w-32">
                <div className="bg-gray-100 p-1 text-center rounded-md">
                  <p className="text-green-500 ">
                    {MATERIAL_STOCK_STATUS.IN_STOCK.description}
                  </p>
                </div>
              </div>
            ) : (
              <div className="w-32">
                <div className="bg-gray-100 p-1 text-center rounded-md">
                  <p className="text-red-500 ">
                    {' '}
                    {MATERIAL_STOCK_STATUS.OUT_STOCK.description}
                  </p>
                </div>
              </div>
            )}{' '}
          </div>
        )}
      </TableCell>
      <TableCell>
        <b>{selectedLot?.material?.type_code}</b> {selectedLot?.material?.name}{' '}
      </TableCell>
      <TableCell>
        {selectedLot?.receipt_date
          ? dayjs(selectedLot?.receipt_date)
              .locale('th')
              .format('D MMM BBBB HH:mm')
          : '-'}
      </TableCell>{' '}
      {imsSetting?.display?.purchase_date && (
        <TableCell>
          {selectedLot?.purchase_date
            ? dayjs(selectedLot?.purchase_date)
                .locale('th')
                .format('D MMM BBBB')
            : '-'}
        </TableCell>
      )}
      <TableCell>
        {_.map(paritedLotList, (each, pairIndex) => (
          <EachLotDurationOnTimePairOnTableRow
            amountOfEmployee={1}
            foundWorkType={each.workType}
            information={information}
            representEndLot={each.endLot}
            representStartLot={each.startLot}
            timePair={each.timePair}
            timeUsage={each.timeUsage}
            key={pairIndex}
          />
        ))}
      </TableCell>
      <TableCell>
        <Link to={`/ims/material-stock-lot/${selectedLot?._id}`}>
          <Button size="small" variant="contained" color="info" fullWidth>
            รายละเอียด
          </Button>
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default MaterialDurationTableRow;

MaterialDurationTableRow.propTypes = {
  selectedLot: PropTypes.object,
  showStatus: PropTypes.bool,
  imsSetting: PropTypes.object,
  index: PropTypes.number,
};
