import React, { useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { Link } from 'react-router-dom';
import {
  BackButton,
  DesktopDatepicker,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import {
  Button,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import BuddhistEra from 'dayjs/plugin/buddhistEra';
import dayDuration from 'dayjs/plugin/duration';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { currencyFormatter } from '@/utils/functions';

import 'dayjs/locale/th';

import graphqlClient from '../../configs/graphqlClient';

dayjs.locale('th');
dayjs.extend(BuddhistEra);
dayjs.extend(dayDuration);

const DailyReport = ({ selectedDay, dayIndex }) => {
  const [currentPrice, setCurrentPrice] = useState();
  const query = gql`
    query FindMaterialStockLot($input: MaterialStockLotInput) {
      findMaterialStockLots(input: $input) {
        rows {
          price
        }
      }
    }
  `;
  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        input: {
          startDate: dayjs(selectedDay).startOf('day').format('YYYY-MM-DD'),
          endDate: dayjs(selectedDay).endOf('day').format('YYYY-MM-DD'),
        },
      });
      const tempData = queryResult?.findMaterialStockLots;
      const priceMatrix = _.map(tempData?.rows, (eachRow) =>
        (eachRow.price ? parseFloat(eachRow.price) : 0),);
      const sumPrice = _.sum(priceMatrix);
      setCurrentPrice(sumPrice);
    } catch (err) {
      console.error('Error On Fetch Inside Process', err);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [selectedDay]);

  return (
    <TableRow>
      <TableCell>{dayIndex + 1}</TableCell>
      <TableCell>{dayjs(selectedDay).format('ddd D MMM BBBB')}</TableCell>
      <TableCell>{currencyFormatter.format(currentPrice)} บาท</TableCell>

      <TableCell>
        <Link
          to={`/ims/material-cost/daily/detail/${dayjs(selectedDay).format(
            'YYYY-MM-DD',
          )}`}
        >
          <Button variant="contained" color="primary" size="small">
            รายละเอียด
          </Button>
        </Link>
      </TableCell>
    </TableRow>
  );
};

const DailyMaterialCost = ({ title, subtitle }) => {
  const [currentDay, setCurrentDay] = useState(dayjs().toDate());

  const dayIndexList = [0, 1, 2, 3, 4, 5, 6];

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const displayDurationTab = () => (
    <div>
      <Card>
        <CardContent>
          <div className="flex flex-wrap justify-between">
            <div>
              <DesktopDatepicker
                fullWidth
                label="เลือกวันที่"
                setValue={setCurrentDay}
                value={currentDay}
                size="small"
              />
            </div>
            <div className="flex gap-1">
              <Button
                variant="outlined"
                startIcon={<ChevronLeft size="16" className="self-center" />}
                onClick={() => {
                  setCurrentDay(dayjs(currentDay).subtract(7, 'day'));
                }}
              >
                ก่อนหน้า
              </Button>
              <div className=" text-xl font-semibold text-center"></div>
              <Button
                variant="outlined"
                endIcon={<ChevronRight size="16" className="self-center" />}
                onClick={() => {
                  setCurrentDay(dayjs(currentDay).add(7, 'day'));
                }}
              >
                ต่อไป
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
  return (
    <div>
      {renderTitle()}
      <div className="flex flex-row justify-start pb-4">
        <div>
          <BackButton />
        </div>
      </div>
      <div>{displayDurationTab()}</div>
      <div className="my-2">
        <Paper>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className="font-bold">ลำดับที่</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold"> วันที่</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold">ต้นทุนวัตถุดิบ</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold">ดำเนินการ</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_.map(dayIndexList, (eachDay, index) => (
                  <DailyReport
                    dayIndex={index}
                    selectedDay={dayjs(currentDay)
                      .subtract(eachDay, 'day')
                      .toDate()}
                    key={index}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
};

DailyMaterialCost.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DailyReport.propTypes = {
  selectedDay: PropTypes.object,
  dayIndex: PropTypes.number,
};

export default DailyMaterialCost;
