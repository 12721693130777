import { BaseAction } from '../../class';
import {
  QUOTATION_ALL,
  QUOTATION_DELETE,
  QUOTATION_ERROR,
  QUOTATION_GET,
  QUOTATION_LOADING,
  QUOTATION_POST,
  QUOTATION_PUT,
} from '../types';

const quotationAction = new BaseAction('quotation', {
  allConst: QUOTATION_ALL,
  getConst: QUOTATION_GET,
  postConst: QUOTATION_POST,
  putConst: QUOTATION_PUT,
  deleteConst: QUOTATION_DELETE,
  errorConst: QUOTATION_ERROR,
  loadingConst: QUOTATION_LOADING,
});

export const quotationCreate = (data) => quotationAction.create(data);
export const quotationAll = ({ name, page, size, ...query }) =>
  quotationAction.getAllData({ name, page, size, ...query });
export const quotationGet = (id) => quotationAction.getOneData(id);
export const quotationPut = (id, data) => quotationAction.editOne(id, data);
export const quotationDelete = (id) => quotationAction.deleteOne(id);
export const quotationStateSet = (data) => quotationAction.stateSet(data);
export const quotationStateOneSet = (data) => quotationAction.stateOneSet(data);
export const quotationReset = () => quotationAction.reset();
export const quotationError = () => quotationAction.onError();
