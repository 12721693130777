import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import {
  NotAuthorized,
  NotFound,
} from '@iarcpsu/emanufac-components/src/components/Error';
import { MainFooter } from '@iarcpsu/emanufac-components/src/components/Footers';
import { Loading } from '@iarcpsu/emanufac-components/src/components/Loading';
import { HomeNavbar } from '@iarcpsu/emanufac-components/src/components/Navbars';
import { MainSidebar } from '@iarcpsu/emanufac-components/src/components/Sidebar';

import * as actions from '../redux/actions';
import accessRight from '../utils/functions/accessRight';
import {
  CreateOrder,
  CreateQuotation,
  DecreaseOrder,
  DetailOrder,
  EditOrder,
  Orders,
  Quotations,
} from '../views/SPM';
// Sales and Purchase Management
export const SPM = () => {
  const module = 'SPM';
  const prefix = '/spm';
  const name = 'ฝ่ายขาย';
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);
  const information = useSelector((state) => state.information);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };

  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout: SPM');
    dispatch(actions.meGet());
    dispatch(actions.informationAll());
    dispatch(actions.availableModuleAll());
    return () => {};
  }, []);

  if (!me?.userData) {
    return <Loading />;
  }

  if (accessRight(me, module, 0, availableModule)) {
    return (
      <div className="min-h-screen">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ฝ่ายขาย - {information?.name || 'E-Manufac'}</title>
        </Helmet>
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="lg:ml-64  min-h-screen  pt-16 px-8 ">
          <div className="py-4">
            <Switch>
              <Redirect exact from={`${prefix}`} to={`${prefix}/orders`} />
              <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
              <Route exact path={`${prefix}/orders`}>
                <Orders title="จัดการออเดอร์" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/order/create`}>
                <CreateOrder title="เพิ่มออเดอร์" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/order/edit/:id`}>
                <EditOrder title="แก้ไขออเดอร์" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/order/decrease/:id`}>
                <DecreaseOrder title="ตัดสต๊อกสินค้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/order/detail/:id`}>
                <DetailOrder title="รายละเอียดออเดอร์" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/quotation`}>
                <Quotations title="ใบเสนอราคา" subtitle={name} />
              </Route>{' '}
              <Route exact path={`${prefix}/quotation/create`}>
                <CreateQuotation title="สร้างใบเสนอราคา" subtitle={name} />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <NotAuthorized />
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    </div>
  );
};

export default SPM;
