import React, { useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { WORK_SHIFT } from '@/utils/constants';

import { DesktopDatepicker } from '../../DatePicker';
import ProductAmountInput from '../../Inputs/ProdOrMatQuantityInput';
import MeasurementAddingRenderingComponent from '../../Measurement/MeasurementAddingRenderingComponent';

import ProcessCustomerAddForm from './ProcessCustomerAddForm';
import SelectMaterialForm from './SelectMaterialForm';

/**
 * @function ProductAndMaterialSelectForm
 * @memberof Form/MMS
 * @description ฟอร์มย่อยใน Create Manufacturing Order ที่เกี่ยวการการเพิ่มสินค้า/วัตถุดิบ แต่ละชิ้น
 * ในการผลิต 1 คำสั่งผลิต
 * @returns {React.Component} Form Component
 */
export function ProductAndMaterialSelectForm({
  errors,
  control,
  product,
  watch,
  material,
  manufacturingOrder,
  information,
  processTemplate,
  customer,
  setValue,
  machine,
  isReady,
  disableAddButton = false,
  bomTemplate,
  baseProject,
  showMaterial = true,
}) {
  const [reload, setReload] = useState(false);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'processes',
  });

  const {
    setting: { mms: mmsSetting },
  } = information;

  const setMaterialFromProcessTemplate = (
    currentProcessTemplate,
    processIndex,
  ) => {
    let allMaterials = [];

    // Find Material of Each Step and Merge them
    _.map(currentProcessTemplate?.steps, (_eachStep) => {
      const fullDataEachStepMaterial = _.map(
        _eachStep?.materials,
        (eachMaterial) => {
          const fullMaterialData = _.find(
            material,
            (eachFullMaterial) =>
              eachFullMaterial?._id === eachMaterial?.material?._id,
          );
          return { material: fullMaterialData };
        },
      );

      allMaterials = _.concat(allMaterials, fullDataEachStepMaterial);
    });

    // Set Merged Material into from stage
    if (!_.isEmpty(allMaterials) && !_.includes(allMaterials, null)) {
      setValue(`processes[${processIndex}].materials`, allMaterials);
    }

    setReload(!reload);
  };

  // In Product have bill of materials (BOM) array
  // BOM is quantity of material per 1 product unit
  const handleMaterialFromBom = (productBom, productQuantity, processIndex) => {
    const usingMaterial = _.map(productBom, (eachMaterialInBom) => ({
      ...eachMaterialInBom,
      begin: eachMaterialInBom?.amount * productQuantity,
      selected_unit: eachMaterialInBom?.material?.unit_input?._id,
    }));

    if (!_.isEmpty(usingMaterial)) {
      setValue(`processes[${processIndex}].materials`, usingMaterial);
      setValue(`processes[${processIndex}].noMaterial`, false);
    }
    setReload(!reload);
  };

  const handleAssignFormular = (
    selectedProcessTemplate,
    selectedBomTemplate,
    processIndex,
  ) => {
    const quantity = watch(`processes[${processIndex}].quantity`);
    const foundBomTemplate = _.find(
      bomTemplate?.rows,
      (each) => each?._id === selectedBomTemplate?._id,
    );
    console.log('Bom Template.rows', bomTemplate?.rows);
    const foundProcessTemplate = _.find(
      processTemplate?.rows,
      (each) => each?._id === selectedProcessTemplate?._id,
    );

    setValue(
      `processes[${processIndex}].process_template`,
      foundProcessTemplate,
    );
    setValue(`processes[${processIndex}].createFromTemplate`, true);

    handleMaterialFromBom(
      foundBomTemplate?.bill_of_materials,
      quantity,
      processIndex,
    );
  };

  const displayProduct = () => (
    <div className="flex flex-wrap">
      {fields.map((_field, index) => (
        <div
          key={_field.id}
          className={`flex flex-wrap py-2 w-full  ${
            showMaterial ? '' : 'lg:w-1/2'
          } `}
        >
          <div className={`w-full ${showMaterial ? 'lg:w-5/12' : 'px-2'} `}>
            <Card className="px-2 py-8 h-full flex flex-wrap ">
              <div>
                {watch(`processes[${index}].product`) &&
                  !watch(`processes[${index}].createFromTemplate`) &&
                  information?.setting?.mms?.automaticWorkCreate &&
                  _.isEmpty(watch(`processes[${index}].product`)?.steps) && (
                    <div className="my-1">
                      <Alert severity="error">
                        <div className="flex w-full justify-between gap-2">
                          <div className="self-center">
                            สินค้าชิ้นนี้ยังไม่มีการสร้างวิธีการผลิต
                          </div>
                          <Link
                            to={`/mms/product-process/edit/${
                              watch(`processes[${index}].product`)?._id
                            }`}
                          >
                            <Button size="small" variant="contained">
                              สร้าง
                            </Button>
                          </Link>
                        </div>
                      </Alert>
                    </div>
                  )}
              </div>
              {isReady && mmsSetting?.allowProduceMaterial && (
                <div className="w-full px-1 py-2 flex justify-center">
                  <div className="border-1 rounded-md border p-2">
                    <Controller
                      control={control}
                      name={`processes[${index}].produce_material_instead`}
                      defaultValue={
                        watch(`processes[${index}].produce_material_instead`) ||
                        false
                      }
                      render={({ field }) => (
                        <FormControl>
                          <div className="flex gap-2">
                            <div className="self-center text-black font-system">
                              ผลิตสินค้า
                            </div>
                            <FormControlLabel
                              control={
                                <Switch
                                  {...field}
                                  onChange={() => {
                                    field.onChange(!field?.value);
                                    setValue(
                                      `processes[${index}].createFromTemplate`,
                                      true,
                                    );
                                  }}
                                  defaultChecked={field?.value}
                                  defaultValue={field?.value}
                                />
                              }
                              label="ผลิตวัตถุดิบ"
                            />
                          </div>
                        </FormControl>
                      )}
                    />
                  </div>
                </div>
              )}{' '}
              <div className="w-full flex flex-wrap ">
                {isReady &&
                  !mmsSetting?.allowUseBaseProject &&
                  !watch(`processes[${index}].produce_material_instead`) &&
                  (!product?.isLoading && product?.isCompleted ? (
                    <div className="w-full   px-1 py-2">
                      <Controller
                        name={`processes[${index}].product`}
                        control={control}
                        rules={{ required: true }}
                        defaultValue={
                          watch(`processes[${index}].product`) ||
                          product?.rows?.[0]
                        }
                        render={({ field }) => (
                          <Autocomplete
                            size={'small'}
                            disableClearable
                            disabled={manufacturingOrder?.process?.[index]}
                            {...field}
                            onChange={(e, newValue) => {
                              field.onChange(newValue);
                              setReload(!reload);
                            }}
                            options={_.map(
                              product?.rows,
                              (eachProduct, prodIndex) => ({
                                ...eachProduct,
                                index: prodIndex,
                              }),
                            )}
                            getOptionLabel={(option) => {
                              if (option?.type_code) {
                                return ` ${option?.type_code || ''}  ${
                                  option?.name
                                }`;
                              }
                              return ` ${option?.name}`;
                            }}
                            required
                            renderInput={(params) => (
                              <TextField {...params} label="สินค้า" required />
                            )}
                          />
                        )}
                      />
                    </div>
                  ) : (
                    <LinearProgress />
                  ))}{' '}
                {watch(`processes[${index}].produce_material_instead`) &&
                  !mmsSetting?.allowUseBaseProject && (
                    <div className="w-full   px-1 py-2">
                      <Controller
                        name={`processes[${index}].product_as_material`}
                        control={control}
                        rules={{ required: true }}
                        defaultValue={
                          watch(`processes[${index}].product_as_material`) ||
                          material?.[0]
                        }
                        render={({ field }) => (
                          <Autocomplete
                            size={'small'}
                            disableClearable
                            disabled={manufacturingOrder?.process?.[index]}
                            {...field}
                            onChange={(e, newValue) => {
                              field.onChange(newValue);
                              setReload(!reload);
                            }}
                            options={_.map(
                              material,
                              (eachMaterial, matIndex) => ({
                                ...eachMaterial,
                                index: matIndex,
                              }),
                            )}
                            getOptionLabel={(option) => {
                              if (option?.type_code) {
                                return `${option?.index || ''} ${
                                  option?.type_code || ''
                                } | ${option?.name}`;
                              }
                              return `${option?.index || ''} ${option?.name}`;
                            }}
                            required
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="วัตถุดิบที่เป็นผลผลิต"
                                required
                              />
                            )}
                          />
                        )}
                      />
                    </div>
                  )}
                {mmsSetting?.allowUseBaseProject &&
                  !_.isEmpty(baseProject?.rows) && (
                    <div className="w-full   px-1 py-2">
                      <Controller
                        name={`processes[${index}].base_project`}
                        control={control}
                        rules={{ required: true }}
                        defaultValue={
                          watch(`processes[${index}].base_project`) || null
                        }
                        render={({ field }) => (
                          <Autocomplete
                            size={'small'}
                            disableClearable
                            disabled={manufacturingOrder?.process?.[index]}
                            {...field}
                            onChange={(e, newValue) => {
                              field.onChange(newValue);
                              setReload(!reload);
                            }}
                            options={_.map(
                              baseProject?.rows,
                              (eachBaseProject, baseProjectIndex) => ({
                                ...eachBaseProject,
                                index: baseProjectIndex,
                              }),
                            )}
                            getOptionLabel={(option) => {
                              if (option?.type_code) {
                                return `${option?.type_code || ''}  ${
                                  option?.name
                                }`;
                              }
                              return `${option?.name}`;
                            }}
                            required
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="โปรเจกต์/สินค้าพื้นฐาน"
                                required
                              />
                            )}
                          />
                        )}
                      />
                      <div>
                        {watch(`processes[${index}].base_project`) && (
                          <div className="my-2">
                            <Alert severity="info">
                              <div className="font-semibold">
                                {
                                  watch(`processes[${index}].base_project`)
                                    ?.name
                                }
                              </div>
                              {watch(`processes[${index}].base_project`)
                                ?.attach_process_template && (
                                <div className="">
                                  <div>
                                    สูตรการผลิต{' '}
                                    {
                                      watch(`processes[${index}].base_project`)
                                        ?.process_template?.name
                                    }
                                  </div>
                                </div>
                              )}
                              {watch(`processes[${index}].base_project`)
                                ?.attach_bom_template && (
                                <div className="">
                                  <div>
                                    สูตร BOM{' '}
                                    {
                                      watch(`processes[${index}].base_project`)
                                        ?.bom_template?.name
                                    }
                                  </div>
                                </div>
                              )}
                              {(watch(`processes[${index}].base_project`)
                                ?.attach_bom_template ||
                                watch(`processes[${index}].base_project`)
                                  ?.attach_process_template) && (
                                <div className="my-2">
                                  <div>
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() =>
                                        handleAssignFormular(
                                          watch(
                                            `processes[${index}].base_project`,
                                          )?.process_template,
                                          watch(
                                            `processes[${index}].base_project`,
                                          )?.bom_template,
                                          index,
                                        )
                                      }
                                    >
                                      จัดสูตรการผลิต
                                    </Button>
                                  </div>
                                </div>
                              )}
                            </Alert>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                {!mmsSetting?.allowUseBaseProject &&
                  watch(`processes[${index}].noQuantity`) === false &&
                  !watch(`processes[${index}].produce_material_instead`) && (
                    <div className="w-full  px-1 py-2">
                      <ProductAmountInput
                        control={control}
                        index={index}
                        reload={reload}
                        setReload={setReload}
                        watch={watch}
                        setValue={setValue}
                        selectedProduct={watch(`processes[${index}].product`)}
                        controllerName={`processes[${index}].quantity`}
                        artificialControllerName={`processes[${index}].quantity_articial`}
                        selectedUnitControllerName={`processes[${index}].selected_unit`}
                      />
                    </div>
                  )}
                {!mmsSetting?.allowUseBaseProject &&
                  watch(`processes[${index}].noQuantity`) === false &&
                  watch(`processes[${index}].produce_material_instead`) && (
                    <div className="w-full  px-1 py-2">
                      <ProductAmountInput
                        control={control}
                        index={index}
                        reload={reload}
                        setReload={setReload}
                        watch={watch}
                        setValue={setValue}
                        selectedProduct={watch(
                          `processes[${index}].product_as_material`,
                        )}
                        controllerName={`processes[${index}].quantity`}
                        artificialControllerName={`processes[${index}].pam_quantity_articial`}
                        selectedUnitControllerName={`processes[${index}].pam_selected_unit`}
                      />
                    </div>
                  )}
                {mmsSetting?.allowUseBaseProject &&
                  watch(`processes[${index}].noQuantity`) === false && (
                    <div className="w-full  px-1 py-2">
                      <ProductAmountInput
                        control={control}
                        index={index}
                        reload={reload}
                        setReload={setReload}
                        watch={watch}
                        setValue={setValue}
                        selectedProduct={watch(
                          `processes[${index}].base_project`,
                        )}
                        controllerName={`processes[${index}].quantity`}
                        artificialControllerName={`processes[${index}].bp_quantity_articial`}
                        selectedUnitControllerName={`processes[${index}].bp_selected_unit`}
                      />
                    </div>
                  )}
              </div>
              {information?.setting?.mms?.manufacturingOrder?.showPrice && (
                <div className="w-full flex flex-wrap">
                  <div className="w-full lg:w-1/2 px-1 py-2">
                    <Controller
                      name={`processes[${index}].price_per_unit`}
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setReload(!reload);
                          }}
                          label="ราคาต่อหน่วย"
                          fullWidth
                          defaultValue={1}
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'center' },
                          }}
                          size="small"
                        />
                      )}
                    />{' '}
                  </div>
                  <div className="w-full lg:w-1/2 px-1 py-2">
                    <TextField
                      label="ราคารวม"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                      value={(
                        (parseFloat(watch(`processes[${index}].quantity`)) ||
                          1) *
                        parseFloat(
                          watch(`processes[${index}].price_per_unit`) || 0,
                        )
                      ).toFixed(2)}
                      size="small"
                    />
                  </div>
                </div>
              )}
              {mmsSetting?.showBatchNumber && (
                <div className="w-full  px-1 py-2">
                  <Controller
                    name={`processes[${index}].batch_no`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="หมายเลข Batch"
                        fullWidth
                        size="small"
                      />
                    )}
                  />{' '}
                </div>
              )}
              <div className="w-full  px-1 py-2">
                <Controller
                  name={`processes[${index}].remark`}
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="รายละเอียด"
                      fullWidth
                      size="small"
                      multiline
                    />
                  )}
                />{' '}
              </div>
              {mmsSetting?.machinery?.attachMachine &&
                !_.isEmpty(machine?.rows) && (
                  <div className="w-full lg:w-1/2 py-1 px-2">
                    <Controller
                      name={`processes[${index}].machine`}
                      control={control}
                      defaultValue={null}
                      render={({ field }) => (
                        <Autocomplete
                          size={'small'}
                          {...field}
                          onChange={(e, newValue) => {
                            field.onChange(newValue);
                            setReload(!reload);
                          }}
                          options={machine?.rows}
                          // prettier-ignore
                          getOptionLabel={(option) => `${option?.name}`
                        }
                          renderInput={(params) => (
                            <TextField {...params} label="เลือกเครื่องจักร" />
                          )}
                        />
                      )}
                    />
                  </div>
                )}
              <div className="w-full flex flex-wrap">
                {!_.isEmpty(mmsSetting?.processAdditionalAttribute) &&
                  _.map(
                    mmsSetting?.processAdditionalAttribute,
                    (each, marcIndex) => (
                      <div className="w-full lg:w-1/2 px-1">
                        <MeasurementAddingRenderingComponent
                          control={control}
                          measurementControllerName={`processes[${index}].additional_attribute.${each?.name}`}
                          measurementType={{
                            data_type: each?.type,
                            label: each?.name,
                          }}
                          watch={watch}
                          key={marcIndex}
                        />
                      </div>
                    ),
                  )}
              </div>
              {mmsSetting?.machinery?.attachShiftWorkSelector && (
                <div className="w-full lg:w-1/2 py-1 px-2">
                  <Controller
                    name={`processes[${index}].work_shift`}
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel>กะของการทำงาน</InputLabel>
                        <Select
                          {...field}
                          label="กะของการทำงาน"
                          fullWidth
                          size="small"
                        >
                          {_.map(WORK_SHIFT, (eachShift, workshiftIndex) => (
                            <MenuItem
                              key={workshiftIndex}
                              value={eachShift.status_code}
                            >
                              {eachShift.description}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </div>
              )}
              {mmsSetting?.showManufacturingDate && (
                <div className="w-full lg:w-1/2 py-1 my-1 px-2">
                  <Controller
                    name={`processes[${index}].manufacturing_date`}
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <DesktopDatepicker
                        {...field}
                        fullWidth
                        inputFormat="D/MM/YYYY"
                        label="วันที่ผลิต"
                        size="small"
                      />
                    )}
                  />
                </div>
              )}{' '}
              {mmsSetting?.showExpireDate && (
                <div className="w-full lg:w-1/2 py-1 my-1 px-2">
                  <Controller
                    name={`processes[${index}].expired_date`}
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <DesktopDatepicker
                        {...field}
                        fullWidth
                        label="วันหมดอายุ"
                        size="small"
                        inputFormat="D/MM/YYYY"
                      />
                    )}
                  />
                </div>
              )}
              {mmsSetting?.allowUseMaterialBOM &&
                !_.isEmpty(
                  watch(`processes[${index}].product`)?.bill_of_materials,
                ) && (
                  <div className="w-full px-1 py-2 flex gap-2">
                    <div>รายการนี้มีรายการวัตถุดิบส่วนผสม (BOM)</div>
                    <div>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() =>
                          handleMaterialFromBom(
                            watch(`processes[${index}].product`)
                              ?.bill_of_materials,
                            parseFloat(watch(`processes[${index}].quantity`)),
                            index,
                          )
                        }
                      >
                        คำนวณวัตถุดิบจากส่วนผสม
                      </Button>
                    </div>
                  </div>
                )}
              <div className="w-full mx-1 ">
                <Controller
                  name={`processes[${index}].createFromTemplate`}
                  control={control}
                  //   defaultChecked={}
                  defaultValue={mmsSetting?.usingAsDefault?.usingFormular}
                  render={({ field }) => (
                    <FormControl>
                      <FormControlLabel
                        label="ผลิตจากสูตรการผลิตแทน"
                        disabled={watch(
                          `processes[${index}].produce_material_instead`,
                        )}
                        control={<Checkbox {...field} checked={field?.value} />}
                      />
                    </FormControl>
                  )}
                />
              </div>
              {watch(`processes[${index}].createFromTemplate`) === true &&
                !_.isEmpty(processTemplate?.rows) && (
                  <div className="w-full   px-1 py-2">
                    <Controller
                      name={`processes[${index}].process_template`}
                      control={control}
                      rules={{ required: true }}
                      defaultValue={
                        watch(`processes[${index}].process_template`) || null
                      }
                      render={({ field }) => (
                        <Autocomplete
                          size={'small'}
                          disableClearable
                          {...field}
                          onChange={(e, newValue) => {
                            field.onChange(newValue);
                            setMaterialFromProcessTemplate(newValue, index);
                          }}
                          options={processTemplate?.rows}
                          getOptionLabel={(option) => ` ${option?.name}`}
                          required
                          placeholder="เลือกสูตรการผลิต"
                          renderInput={(params) => (
                            <div>
                              <TextField
                                {...params}
                                label="สูตรการผลิต"
                                required
                              />
                              <div className="text-xs my-1 px-1">
                                ประกอบด้วย{' '}
                                {_.size(
                                  watch(`processes[${index}].process_template`)
                                    ?.steps,
                                )}{' '}
                                ขั้นตอนการผลิต
                              </div>
                            </div>
                          )}
                        />
                      )}
                    />
                  </div>
                )}
              {mmsSetting?.allowUseMaterialBOM && (
                <div className="w-full mx-1">
                  <Controller
                    name={`processes[${index}].useBomTemplate`}
                    defaultValue={mmsSetting?.usingAsDefault?.usingBomTemplate}
                    control={control}
                    //   defaultChecked={}
                    render={({ field }) => (
                      <FormControl>
                        <FormControlLabel
                          label="เลือกวัตถุดิบส่วนผสม(BOM) จากสูตรส่วนผสม"
                          control={
                            <Checkbox {...field} checked={field?.value} />
                          }
                        />
                      </FormControl>
                    )}
                  />
                </div>
              )}
              {mmsSetting?.allowUseMaterialBOM &&
                watch(`processes[${index}].useBomTemplate`) === true && (
                  <div className="w-full mx-1 flex ">
                    <div className="w-2/3">
                      {!_.isEmpty(bomTemplate?.rows) && (
                        <Controller
                          name={`processes[${index}].bom_template`}
                          control={control}
                          //   defaultChecked={}
                          render={({ field }) => (
                            <Autocomplete
                              size={'small'}
                              disableClearable
                              {...field}
                              onChange={(e, newValue) => {
                                field.onChange(newValue);
                                //  setMaterialFromProcessTemplate(newValue, index);
                              }}
                              options={bomTemplate?.rows}
                              getOptionLabel={(option) => ` ${option?.name}`}
                              required
                              placeholder="เลือกสูตรส่วนผสม (BOM)"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="เลือกสูตรส่วนผสม (BOM)"
                                />
                              )}
                            />
                          )}
                        />
                      )}
                      {_.isEmpty(bomTemplate?.rows) && (
                        <div>ไม่มีรายการสูตรส่วนผสมในระบบ</div>
                      )}
                    </div>
                    <div className="w-1/3  justify-center flex self-center">
                      <div>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() =>
                            handleMaterialFromBom(
                              watch(`processes[${index}].bom_template`)
                                ?.bill_of_materials,
                              parseFloat(watch(`processes[${index}].quantity`)),
                              index,
                            )
                          }
                        >
                          คำนวณวัตถุดิบ
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              <div className="w-full mx-1">
                <Controller
                  name={`processes[${index}].attachCustomer`}
                  control={control}
                  defaultValue={mmsSetting?.usingAsDefault?.attachCustomer}
                  render={({ field }) => (
                    <FormControl>
                      <FormControlLabel
                        label="ระบุลูกค้าของสินค้า"
                        control={<Checkbox {...field} checked={field?.value} />}
                      />
                    </FormControl>
                  )}
                />
              </div>
              {watch(`processes[${index}].attachCustomer`) === true &&
                !_.isEmpty(customer?.rows) && (
                  <ProcessCustomerAddForm
                    control={control}
                    customer={customer}
                    reload={reload}
                    setReload={setReload}
                    index={index}
                  />
                )}
              <div className="w-full mx-1">
                <Controller
                  name={`processes[${index}].noQuantity`}
                  defaultValue={mmsSetting?.usingAsDefault?.attachAmount}
                  control={control}
                  //   defaultChecked={}
                  render={({ field }) => (
                    <FormControl>
                      <FormControlLabel
                        label="ไม่ระบุจำนวน"
                        control={<Checkbox {...field} checked={field?.value} />}
                      />
                    </FormControl>
                  )}
                />
              </div>
              <div className="w-full mx-1">
                <Controller
                  name={`processes[${index}].noMaterial`}
                  defaultValue={!mmsSetting?.usingAsDefault?.addMaterial}
                  control={control}
                  //   defaultChecked={}
                  render={({ field }) => (
                    <FormControl>
                      <FormControlLabel
                        label="ไม่ระบุวัตถุดิบ"
                        control={<Checkbox {...field} checked={field?.value} />}
                      />
                    </FormControl>
                  )}
                />
              </div>
              <div className="w-full mx-1">
                <Controller
                  name={`processes[${index}].changeUnit`}
                  control={control}
                  //   defaultChecked={}
                  render={({ field }) => (
                    <FormControl>
                      <FormControlLabel
                        label="เปลี่ยนหน่วยสินค้า"
                        control={<Checkbox {...field} checked={field?.value} />}
                      />
                    </FormControl>
                  )}
                />
              </div>
              {watch(`processes[${index}].changeUnit`) === true && (
                <div className="w-full px-1 py-2">
                  <Controller
                    name={`processes[${index}].additional_unit`}
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="หน่วยสินค้า"
                        fullWidth
                        size="small"
                        helperText={errors.additional_unit && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </div>
              )}
            </Card>
          </div>

          {showMaterial && (
            <div className="w-full lg:w-7/12 lg:pl-2">
              <div className="flex flex-wrap">
                {' '}
                {watch(`processes[${index}].noMaterial`) !== true && (
                  <div className="w-full lg:w-11/12 ">
                    {' '}
                    <Card>
                      <div className="w-full">
                        <SelectMaterialForm
                          errors={errors}
                          control={control}
                          material={material}
                          productIndex={index}
                          watch={watch}
                          setValue={setValue}
                        />
                      </div>
                    </Card>
                  </div>
                )}
                <div className="w-full py-2 lg:py-0 lg:w-1/12 lg:pl-2">
                  <Button
                    className="h-full w-full"
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      remove(index);
                    }}
                    disabled={
                      manufacturingOrder?.process?.[index]
                        ?.isRequestMaterial === true
                    }
                  >
                    ลบ
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );

  return (
    <div>
      {displayProduct()}
      {!disableAddButton && (
        <div className="flex">
          <Button
            color="success"
            variant="outlined"
            onClick={() => {
              append({
                product: product?.rows[0],
                quantity: 1,
                materials: [{ material: material[0], begin: 1 }],
                noQuantity: false,
              });
            }}
          >
            เพิ่ม
          </Button>
        </div>
      )}
    </div>
  );
}

ProductAndMaterialSelectForm.propTypes = {
  errors: PropTypes.object,
  material: PropTypes.object,
  product: PropTypes.object,
  control: PropTypes.object,
  watch: PropTypes.func,
  manufacturingOrder: PropTypes.object,
  information: PropTypes.object,
  processTemplate: PropTypes.object,
  customer: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  machine: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  setValue: PropTypes.func,
  isReady: PropTypes.bool,
  disableAddButton: PropTypes.bool,
  bomTemplate: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  baseProject: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  showMaterial: PropTypes.bool,
};

export default ProductAndMaterialSelectForm;
