import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { WordLocalizeContext } from '@/contexts/WordLocalizeContext';

import MaterialDurationTableRow from './MaterialDurationTableRow';

function MaterialLotDurationTable({
  imsSetting,
  materialStockLot,
  page,
  total,
  size,
  setPage,
  setSize,
  showStatus = false,
}) {
  const { findWord } = useContext(WordLocalizeContext);
  return (
    <div>
      <Card>
        <TableContainer>
          <Table className="border-lg mb-4">
            <colgroup>
              <col width="5%" />
              <col width="8%" />
              <col width="8%" />
              <col width="8%" />
              {imsSetting?.display?.purchase_date && <col width="8%" />}
              <col width="45%" />
              <col width="10%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">เลขอ้างอิง</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">วัตถุดิบ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">
                    {findWord('recipt_date') || 'วันที่รับวัตถุดิบ'}
                  </div>
                </TableCell>{' '}
                {imsSetting?.display?.purchase_date && (
                  <TableCell>
                    <div className="font-bold">
                      {findWord('purchase_date') || 'วันที่ซื้อ'}
                    </div>
                  </TableCell>
                )}{' '}
                <TableCell>
                  <div className="font-bold"> ช่วงเวลาการทำงาน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(materialStockLot?.rows) ? (
                materialStockLot?.rows.map((_lot, index) => (
                  <MaterialDurationTableRow
                    imsSetting={imsSetting}
                    index={index}
                    key={index}
                    selectedLot={_lot}
                    showStatus={showStatus}
                  />
                ))
              ) : (
                <TableRow key={''}>
                  <TableCell colSpan={12}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>{' '}
          <TablePagination
            component="div"
            page={page - 1}
            count={total || 1}
            rowsPerPage={size}
            onRowsPerPageChange={(e) => {
              setSize(e.target.value);
              setPage(1);
            }}
            onPageChange={(e, newValue) => {
              setPage(newValue + 1);
            }}
          />
        </TableContainer>{' '}
      </Card>
    </div>
  );
}

export default MaterialLotDurationTable;

MaterialLotDurationTable.propTypes = {
  imsSetting: PropTypes.object,
  materialStockLot: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  page: PropTypes.number,
  total: PropTypes.number,
  size: PropTypes.number,
  setPage: PropTypes.func,
  setSize: PropTypes.func,
  showStatus: PropTypes.bool,
};
