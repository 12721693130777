import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Error,
  Loading,
  QuotationTable,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';

/**
 * @function Quotations
 * @description Display a collections or a list of Quotations from database
 */

export default function Quotations({ title, subtitle }) {
  const dispatch = useDispatch();
  const quotation = useSelector((state) => state.quotation);
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [total, setTotal] = useState(undefined);

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.quotationAll({
          page,
          size,
        }),
      );
    } catch (error) {
      dispatch(actions.quotationError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size]);

  useEffect(() => {
    if (quotation?.rows) {
      setTotal(quotation?.total);
    }

    return () => {};
  }, [quotation]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (quotation.isLoading) {
    return <Loading />;
  }
  if (!quotation.isLoading && quotation.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          {renderTitle()}
          <div>
            <Link to={'/spm/quotation/create'}>
              <Button variant="contained">เพิ่ม</Button>
            </Link>
          </div>
        </div>
        <div className="my-2">
          <QuotationTable
            quotation={quotation}
            page={page}
            size={size}
            total={total}
            setPage={setPage}
            setSize={setSize}
          />
        </div>
      </div>
    );
  }
  return <Error />;
}

Quotations.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Quotations.defaultProps = {
  title: '',
  subtitle: '',
};
