import React from 'react';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import 'dayjs/locale/th';

export default function QuotationTable({
  quotation,
  page,
  size,
  total,
  setPage,
  setSize,
  information,
  handleDelete,
  handlePrintPdf,
}) {
  return (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            {/* <colgroup>
              <col width="8%" />
              <col width="10%" />
              <col width="15%" />
              <col width="20%" />
              <col width="5%" />
              <col width="10%" />
              <col width="30%" />
            </colgroup> */}
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วันที่กำหนดจัดส่ง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ลูกค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">สินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">สถานะ</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">ผู้เสนอราคา</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">หมายเหตุ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(quotation?.rows) ? (
                quotation?.rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      {dayjs(row?.expected_date)
                        .locale('th')
                        .format('DD MMM BBBB')}
                    </TableCell>
                    <TableCell>{`${row?.customer?.name}`}</TableCell>
                    <TableCell>
                      {_.map(row?.process, (eachProdOfOrder, prodIndex) => (
                        <div key={prodIndex}>
                          <span>
                            {prodIndex + 1}
                            {'. '}
                          </span>
                          <span className=" font-semibold font-display">
                            {eachProdOfOrder?.product?.type_code}
                          </span>{' '}
                          <span>{eachProdOfOrder?.product?.name}</span>{' '}
                          {eachProdOfOrder?.quantity}{' '}
                          {eachProdOfOrder?.product?.unit}
                        </div>
                      ))}
                    </TableCell>

                    <TableCell>
                      <div className="w-64">
                        <div className="py-1 bg-gray-100 rounded-md  text-center"></div>
                      </div>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>{row?.remark}</TableCell>
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          color={'warning'}
                          size={'small'}
                        >
                          แก้ไข
                        </Button>
                        <Button
                          variant="contained"
                          color={'error'}
                          size={'small'}
                          onClick={() => {
                            handleDelete(row?._id);
                          }}
                        >
                          ยกเลิก
                        </Button>
                        <div>
                          <Button
                            variant="contained"
                            color={'secondary'}
                            size={'small'}
                          >
                            รายละเอียด
                          </Button>
                        </div>
                        <Button
                          variant="contained"
                          color={'teal'}
                          size={'small'}
                          onClick={() => {
                            handlePrintPdf(row);
                          }}
                        >
                          พิมพ์
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          onRowsPerPageChange={(event) => {
            setSize(event.target.value);
            setPage(1);
          }}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={(event, newValue) => {
            setPage(newValue + 1);
          }}
        />
      </Paper>
    </div>
  );
}

QuotationTable.propTypes = {
  quotation: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  setPage: PropTypes.func,
  setSize: PropTypes.func,
  information: PropTypes.object,
  handleDelete: PropTypes.func,
  handlePrintPdf: PropTypes.func,
};
